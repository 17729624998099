@font-face {
  font-family: 'Pixellari';
  src: local('Pixellari');
  src: url(./fonts/Pixellari.woff) format('woff');
  src: url(./fonts/Pixellari.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Pixellari';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 26px;
  color: black;
  overflow: hidden;
}

.ScrollBar {
  min-height: calc(100vh - 64px);
}

a {
  text-decoration: none;
}

/* Navbar */

.nav {
  background: #3d4148;
  top: 0%;
  height: 65px;
}

.nav_logo {
  height: 65px;
}

.nav_profile {
  height: 65px;
  image-rendering: pixelated;
  top: 100px;
}

ul {
  margin: 0;
}

.nav_menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
  margin-right: 3rem;
  height: 65px;
  text-align: center;
}

.space {
  flex-grow: 5;
}

.nav_link {
  color: white;
}

.nav_item {
  list-style: none;
  transition: all .2s ease-out;
}

.nav_item:hover {
  transform: scale(1.5);
}

.background {
  min-height: 100vh;
  width: calc(100% + 1px);
  background-image: linear-gradient(rgb(62.5, 32.5, 90.5, 0.6), rgb(134, 37, 37, 0.6)), url(./img/TestImage.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: center;
  background-size: fixed;
}

.background2 {
  min-height: 100vh;
  width: calc(100% + 1px);
  background-image: linear-gradient(rgba(40, 21, 57, 0.9), rgba(80, 22, 22, 0.9));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: center;
  background-size: fixed;
  animation-name: transition1to2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.centered {
  width: calc(100% + 1px);
  position: absolute;
  top: 0%;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 20px;
  }
  .nav_logo {
    display: none;
  }
  .space {
    display: none;
  }
  .nav_menu {
    font-size: 23px;
    justify-content: space-evenly;
    margin-right: 2rem;
    gap: 2rem;
  }
}