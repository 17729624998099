.DeleteMe {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  align-items: center;
  justify-content: center;
  margin-top: 1%;
  margin-right: 5%;
  margin-left: 5%;
  color: white;
}

.DeleteMe>h1 {
  letter-spacing: .2rem;
}

.DeleteMe>p {
  letter-spacing: .1rem;
  color: rgb(255, 118, 118)
}

.DeleteMe>form {
  margin-top: 1%;
  background: #30303a6a;
  border-color: #0a0a0a6a;
  border-style: solid;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: calc(60%);
  padding: 1em;
  align-items: center;
  position: fixed;
  bottom: calc(100vh*0.25 - 100px);
}

.input-label {
  white-space: nowrap;
  font-size: calc(25px);
}

input {
  margin-top: 3%;
  margin-bottom: 2%;
  margin-left: 2%;
  border-radius: 6px;
  border-style: solid;
  border-color: #0a0a0abc;
  font-family: 'Pixellari';
  font-size: calc(25px);
}

.infoLabel {
  align-items: center;
  position: relative;
  bottom:calc(-10vh - 25px);
  font-family: 'Pixellari';
  font-size: calc(20px);
}

.infoLabelError {
  align-items: center;
  position: relative;
  bottom:calc(-10vh - 25px);
  font-family: 'Pixellari';
  font-size: calc(24px);
  color: rgb(255, 118, 118);
}

.infoLabelSuccess {
  align-items: center;
  position: relative;
  bottom:calc(-10vh - 25px);
  font-family: 'Pixellari';
  font-size: calc(24px);
  color: rgb(139, 255, 118);
}

button {
  margin-top: 1%;
  border-radius: 6px;
  border-style: solid;
  color: white;
  background: #0000007e;
  border-color: #0a0a0a;
  font-family: 'Pixellari';
  font-size: calc(25px);
  width: calc(30%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.agreement-label {
  font-size: calc(15px);
  font-family: 'Pixellari';
  display: grid;
  margin-top: 1%;
  grid-template-columns: 2em auto;
  gap: 0em;
  line-height: 1.5;
  text-indent: 0px;
  align-items: left;
}

.form_line {
  width:100%;
  display:inline-block;
  height: 50px;
  margin-top: -30px;
  margin-bottom: 35px;
}

input[type=checkbox], input[type=radio] {
  display:inline-block;
  width:60%;
}

.input-label {
  display:inline-block;
  width:30%;
}

@media screen and (max-width: 768px) {
  .DeleteMe {
    margin-top: 10%;
  }
  .DeleteMe>form {
    width: calc(95%);
    padding: calc(5px);
    bottom: calc(5px);
  }

  .input-label {
    font-size: calc(1em);
  }
  
  input {
    margin-top: 5vh;
    margin-bottom: 2%;
    margin-left: 2%;
    border-radius: 6px;
    border-style: solid;
    border-color: #0a0a0abc;
    font-family: 'Pixellari';
    font-size: calc(1em);
  }

  button {
    font-size: calc(1em);
  }
  
}