.Home {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-right: 5%;
  margin-left: 5%;
  color: white;
}

.Buttons {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1%;
  margin-right: 5%;
  margin-left: 8%;
  gap: 50px;
  color: white;
}

.buttonImg-Itch {
  display: block;
  width: calc(20em);
  height: auto;
}

.buttonImg-GP {
  display: block;
  width: calc(24em);
  height: auto;
}

.Home-logo {
  width: calc(40em);
  image-rendering: pixelated;
}

.Home>p {
  margin-top: 0%;
  max-height: calc(0px);
}

.Home>h1 {
  margin-top: 1%;
  max-height: calc(45px);
}

@media screen and (max-width: 768px) {
  .Home {
    margin-top: 7%;
  }
  
  .Home-logo {
    width: calc(95%);
  }

  .Home>h1 {
    margin-top: 5%;
  }
  

  .Buttons {
    margin-top: 3vh;
    flex-direction: column;
    margin-right: 0%;
    margin-left: 0%;
    margin-bottom: 0%;
    position: fixed;
    bottom: 0;
    gap: 5px;
  }

  .buttonImg-Itch {
    width: calc(85vw);
  }

  .buttonImg-GP {
    width: calc(100vw);
  }

  .Home>p {
    margin-top: 10px;
  }
}