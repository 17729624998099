.privacyPolicy {
    display: flex;
    flex-direction: column;
    font-size: 26px;
    color: white;
    margin-right: 5%;
    margin-left: 5%;
}

.privacyPolicy  > h1 {
    text-align: center;
  }

  .privacyPolicy  > h2 {
    margin-bottom: 3px;
  }

  .privacyPolicy  > p {
    margin-top: 3px;
  }


@media screen and (max-width: 768px) {
    .privacyPolicy {
        font-size: 20px;
    }
}